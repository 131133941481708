<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

const auth = useAuthStore();
const site = useSiteStore();

const navigateToLogin = () => {
  if (auth.loggedIn) {
    auth.logout();
  }
  site.activateModal('login');
};

// If user exits Modal/account options then log them out to enter new password
onUnmounted(() => {
  if (auth.loggedIn) {
    auth.logout();
  }
  site.activateModal('login');
});
</script>
<template>
  <!-- Password successful -->
  <div class="px-2 success">
    <!-- Heading -->
    <div class="text-base font-bold">
      <p class="mt-2 mb-1 text-black dark:text-white text-center">
        {{ $t('password-management') }}
      </p>
    </div>

    <!-- Content -->
    <div class="grid">
      <!-- success icon -->
      <div
        class="field col-12 w-full p-2 field col-12 mb-0 mt-2 flex flex-col items-center"
      >
        <CheckCircleIcon
          class="w-16 dark:text-primary-blue-700 text-primary-blue-400 icon-success--border-lg text-center"
        />
        <p
          class="mt-1 mb-2 text-base dark:text-primary-blue-700 text-primary-blue-400 font-bold text-center"
        >
          {{ $t('change-password-success') }}
        </p>
      </div>
      <!-- Button -->
      <div
        class="field col-12 text-sm w-full p-2 field col-12 mb-0 bg-light-200 dark:bg-dark-900 flex flex-row justify-center"
      >
        <Button
          class="my-2 mx-1 uppercase w-full justify-center text-sm"
          @click="navigateToLogin()"
        >
          {{ auth.loggedIn ? $t('done') : $t('login') }}
        </Button>
      </div>
    </div>
  </div>
</template>
